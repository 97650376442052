import { useMemo } from 'react'
import { useReactTable, type ColumnDef, type CellContext, getCoreRowModel, getExpandedRowModel, flexRender } from '@tanstack/react-table'
import { NumberDisplay, NumberDisplayType, SimpleCell } from '@flyward/platform'
import { type ExpandedComponentDto } from '@flyward/platform/models/DTOs/ExpandedComponentDto'

const getStringValue = (value: unknown) => value as string

const createNumberDisplayCell = (displayType: NumberDisplayType = NumberDisplayType.Integer) => ({
  cell: ({ cell }: CellContext<ExpandedComponentDto, unknown>) => (
    <SimpleCell>
      <NumberDisplay displayType={displayType} value={cell.getValue() as number} />
    </SimpleCell>
  ),
})

const createStringCell = () => ({
  cell: ({ cell }: CellContext<ExpandedComponentDto, unknown>) => <SimpleCell>{getStringValue(cell.getValue())}</SimpleCell>,
})

const generateColumns = (): Array<ColumnDef<ExpandedComponentDto>> => [
  {
    accessorKey: 'totalHoursSinceNew',
    header: () => 'TSN',
    ...createNumberDisplayCell(),
  },
  {
    accessorKey: 'cyclesSinceNew',
    header: () => 'CSN',
    ...createNumberDisplayCell(),
  },
  {
    accessorKey: 'hoursSinceLastPr',
    header: () => 'TSLPR',
    ...createNumberDisplayCell(),
  },
  {
    accessorKey: 'cyclesSinceLastPr',
    header: () => 'CSLPR',
    ...createNumberDisplayCell(),
  },
  {
    accessorKey: 'llpCyclesRemaining',
    header: () => 'LLP FC rem',
    ...createNumberDisplayCell(),
  },
  {
    accessorKey: 'llpDescriptor',
    header: () => 'LLP Descriptor',
    ...createStringCell(),
  },
  {
    accessorKey: 'prFund',
    header: () => 'PR fund',
    ...createNumberDisplayCell(NumberDisplayType.CurrencyRounded),
  },
  {
    accessorKey: 'llpsFund',
    header: () => 'LLP fund',
    ...createNumberDisplayCell(NumberDisplayType.CurrencyRounded),
  },
]

export const StandaloneEngineExpandedDataGrid = ({ engines }: { engines: ExpandedComponentDto[] }) => {
  const columns = useMemo(() => generateColumns(), [])

  const table = useReactTable<ExpandedComponentDto>({
    data: engines ?? [],
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <div className="flex w-full overflow-x-auto">
      <div className="w-[0.4375rem] bg-[linear-gradient(180deg,_#005C64,_#02AEBD66)]"></div>
      <table className="w-full table-fixed">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="px-4 py-2">
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className="w-36 text-left text-xs font-semibold text-text-3">
                    {header.isPlaceholder ? null : <div className="pl-1">{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className="gap-x-10 px-4 py-2">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="w-36 border-2 border-solid border-transparent text-left">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
