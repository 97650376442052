import { type AssetSearchDTO } from '@flyward/assets/models'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { removeReportAssetByAssetId, setSelectedAssetId, type IReportAsset } from '@flyward/platform/store/slices'
import {
  selectFilteredAssetsData,
  selectSelectedAssetId,
  selectUnsortedReportAssets,
} from '@flyward/platform/store/slices/features/flyForward/flyForwardSelectors'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { AssetSelectionBadge } from './AssetSelectionBadge'
import { Button, ButtonVariant, Icon, IconVariant, Input, Size } from '@flyward/platform/components'
import { ReportItemStatus } from '@flyward/forecasts/models/enums'

const SelectedAssetsList = () => {
  const dispatch = useAppDispatch()

  const filteredAssetsData = useAppSelector(selectFilteredAssetsData)
  const availableAssetsData = filteredAssetsData?.items ?? []

  const reportAssets: IReportAsset[] = useAppSelector(selectUnsortedReportAssets)

  const [isInputOpen, setIsInputOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const handleAssetRemoval = (assetId: string) => {
    dispatch(
      removeReportAssetByAssetId({
        assetId,
      }),
    )
  }

  const globalSelectedAssetId = useAppSelector(selectSelectedAssetId)

  useEffect(() => {
    if (isEmpty(searchValue)) {
      setIsInputOpen(false)
    }
  }, [searchValue])

  return (
    <>
      <span className="text-xs font-normal text-text-1">
        {reportAssets?.length} {reportAssets?.length === 1 ? 'asset' : 'assets'} selected
      </span>
      {reportAssets.length > 0 && (
        <div className="flex  w-full flex-row items-center rounded-lg bg-background-light">
          <ul className="flex basis-11/12 flex-row flex-wrap items-center overflow-x-auto text-pretty ">
            {availableAssetsData
              .filter(
                (availableAsset: AssetSearchDTO) =>
                  reportAssets.some((reportAsset) => reportAsset.assetId === availableAsset.assetId) &&
                  availableAsset.serialNumber.includes(searchValue),
              )
              .map((availableAsset: AssetSearchDTO) => {
                const availableReportAssets = reportAssets.find((reportAsset) => reportAsset.assetId === availableAsset.assetId)
                return (
                  <AssetSelectionBadge
                    key={availableAsset.assetId}
                    assetId={availableAsset.assetId}
                    isSelected={availableAsset.assetId === globalSelectedAssetId}
                    status={availableReportAssets?.reportItemStatus ?? ReportItemStatus.Initial}
                    onItemClicked={() => {
                      dispatch(setSelectedAssetId({ assetId: availableAsset.assetId }))
                    }}
                    onClearIconClicked={() => {
                      handleAssetRemoval(availableAsset.assetId)
                    }}
                    text={availableAsset.serialNumber}
                  />
                )
              })}
          </ul>
          <div className="flex basis-1/12 flex-row gap-1 self-start">
            {!isInputOpen ? (
              <Button
                className="ml-auto"
                onClick={() => {
                  setIsInputOpen(true)
                }}
                variant={ButtonVariant.Ghost}
              >
                <Icon
                  className="ml-auto !h-9 !w-9 cursor-pointer rounded-full bg-primary p-1.5 text-black-0"
                  size={Size.None}
                  variant={IconVariant.Search}
                />
              </Button>
            ) : (
              <div
                className="ml-auto flex h-9 w-60 flex-row items-center gap-2 rounded-full border
                    border-solid border-primary bg-black-0 p-1.5 px-2.5 pl-2.5 pr-6"
              >
                <Button
                  onClick={() => {
                    setIsInputOpen(false)
                  }}
                  variant={ButtonVariant.Ghost}
                >
                  <Icon className="cursor-pointer text-primary" size={Size.Medium} variant={IconVariant.Search} />
                </Button>
                <Input
                  hasXButton
                  inputClassName="border-none w-40 pl-0 h-full"
                  setValueAfterValidation={setSearchValue}
                  controlledValue={searchValue}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export { SelectedAssetsList }
