import { type LandingGear } from '@flyward/assets/models'
import { CheckTypesDisplay, formatDate, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'

interface ILGTechnicalTabProps {
  landingGear: LandingGear
}

export const LGTechnicalTab = ({ landingGear }: ILGTechnicalTabProps) => {
  const utilizationAtEvents = landingGear.landingGearUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = utilizationAtEvents.map((event) => {
    return (
      <Fragment key={event.eventType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`TSN @ last ${CheckTypesDisplay(event.eventType)}`}
          info={event.totalHoursSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`CSN @ last ${CheckTypesDisplay(event.eventType)}`}
          info={event.cyclesSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`Last ${CheckTypesDisplay(event.eventType)} date`}
          info={formatDate(event.dateAtLastQualifyingEvent?.toString())}
        />
      </Fragment>
    )
  })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <NumberCellWithLabel
        className="basis-1/6"
        label="TSN"
        info={landingGear.landingGearUtilizationSnapshot.totalHoursSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      <NumberCellWithLabel
        className="basis-1/6"
        label="CSN"
        info={landingGear.landingGearUtilizationSnapshot.cyclesSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      {UtilizationAtEventComponent}
      <div className="basis-1/6" />
    </div>
  )
}
