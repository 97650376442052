/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button, ButtonVariant, Combobox, Dialog, getAssetAge, IconVariant, Size } from '@flyward/platform'
import { useEffect, useState } from 'react'
import { type AssetSearchDTO } from '../../models/AssetSearchDTO'
import { type FleetSearchInput } from '@flyward/assets/models/FleetSearchInput'
import { type IFilterSelection, type IFilterData, type IFilterableData } from '../../models/FilterData'
import { useAppDispatch, useAppSelector } from '@flyward/platform/store/configureHooks'
import { resetFleetFilters } from '@flyward/platform/store/slices/features'
import { isNil } from 'lodash'
import { selectFleetSearchInput, selectHasActiveFilters } from '@flyward/platform/store/slices/features/fleet/fleetSelectors'
import { useGetFiltersQuery } from '@flyward/assets/store'
import { initialFilterData } from '@flyward/platform/store'

export const filterAssets = (assets: AssetSearchDTO[], filters: IFilterData) => {
  const filteredAircrafts = assets.filter((aircraft: AssetSearchDTO) => {
    return (
      (filters.filterSelection.selectedMsn.length === 0 || filters.filterSelection.selectedMsn.includes(aircraft.serialNumber)) &&
      (filters.filterSelection.selectedLessee.length === 0 || filters.filterSelection.selectedLessee.includes(aircraft.lessee)) &&
      (filters.filterSelection.selectedAircraftModel.length === 0 ||
        filters.filterSelection.selectedAircraftModel.includes(aircraft.aircraftModel)) &&
      (filters.filterSelection.selectedEngineType.length === 0 || filters.filterSelection.selectedEngineType.includes(aircraft.engineModel)) &&
      (filters.filterSelection.selectedAircraftAge.length === 0 || filters.filterSelection.selectedAircraftAge.includes(aircraft.dom)) &&
      (filters.filterSelection.selectedRedeliveryDate.length === 0 ||
        filters.filterSelection.selectedRedeliveryDate.includes(aircraft.redeliveryDate)) &&
      (filters.filterSelection.selectedRegions.length === 0 || filters.filterSelection.selectedRegions.includes(aircraft.region)) &&
      (filters.filterSelection.selectedPortfolio.length === 0 || filters.filterSelection.selectedPortfolio.includes(aircraft.portfolio))
    )
  })

  return filteredAircrafts
}

export const updateFilterSelections = (newFilters: IFilterData, prevFilters: IFilterData) => {
  const selections = {
    selectedMsn: prevFilters.filterSelection.selectedMsn,
    selectedLessee: prevFilters.filterSelection.selectedLessee,
    selectedAircraftModel: prevFilters.filterSelection.selectedAircraftModel,
    selectedEngineType: prevFilters.filterSelection.selectedEngineType,
    selectedAircraftAge: prevFilters.filterSelection.selectedAircraftAge,
    selectedRedeliveryDate: prevFilters.filterSelection.selectedRedeliveryDate,
    selectedRegions: prevFilters.filterSelection.selectedRegions,
    selectedPortfolio: prevFilters.filterSelection.selectedPortfolio,
  }

  return {
    ...newFilters,
    ...selections,
  }
}

export interface IAssetFilterDialogProps {
  applyFilter: (filterData: IFilterSelection) => void
  onFilterUpdate: (filter: Partial<FleetSearchInput>) => void
}

interface IAssetsState {
  assets: AssetSearchDTO[]
  filterData: IFilterData
}

const emptyState: IAssetsState = {
  assets: [],
  filterData: initialFilterData,
}

export const AssetFilterDialog = ({ applyFilter: applyFilterExternal, onFilterUpdate }: IAssetFilterDialogProps) => {
  const dispatch = useAppDispatch()
  const [initialState, setInitialState] = useState<IAssetsState>(emptyState)
  const { data: availableFiltersData } = useGetFiltersQuery()

  const searchInput = useAppSelector(selectFleetSearchInput)
  const hasFilters = useAppSelector(selectHasActiveFilters)

  const [filterDataCombobox, setFilterDataCombobox] = useState<IFilterData>({
    filterSelection: initialFilterData.filterSelection,
    availableFilters: availableFiltersData ?? initialFilterData.availableFilters,
  })

  useEffect(() => {
    const initialState: IAssetsState = {
      assets: /* allAssets?.items ?? */ [],
      filterData: {
        filterSelection: initialFilterData.filterSelection,
        availableFilters: availableFiltersData ?? initialFilterData.availableFilters,
      },
    }
    setInitialState(initialState)
  }, [availableFiltersData])

  if (isNil(initialFilterData)) {
    return <></>
  }

  const resetFilterData = () => {
    setFilterDataCombobox((prevData: IFilterData) => {
      return {
        ...prevData,
        filterSelection: {
          selectedMsn: [],
          selectedLessee: [],
          selectedAircraftModel: [],
          selectedEngineType: [],
          selectedAircraftAge: [],
          selectedRedeliveryDate: [],
          selectedRegions: [],
          selectedPortfolio: [],
        },
      }
    })
  }

  const applyFilter = () => {
    applyFilterExternal(filterDataCombobox.filterSelection)
    onFilterUpdate({ ...searchInput, filterData: filterDataCombobox.filterSelection })
  }

  const clearAllFiltersAndApply = () => {
    dispatch(resetFleetFilters())
  }

  const triggerButton = ({ onClick = () => {} }) => {
    const onClickHandler = () => {
      setFilterDataCombobox((prevData: IFilterData) => ({
        ...prevData,
        filterSelection: initialFilterData.filterSelection,
      }))

      onClick()
    }

    return (
      <div className="flex">
        <Button variant={ButtonVariant.Secondary} size={Size.Small} label="Filter" onClick={onClickHandler} leftIcon={IconVariant.FilterList} />
        {hasFilters && (
          <Button variant={ButtonVariant.Ghost} size={Size.Small} label="Clear all" onClick={clearAllFiltersAndApply} leftIcon={IconVariant.Clear} />
        )}
      </div>
    )
  }

  const onSelectedFilterChanged = (values: string[], key: string) => {
    setFilterDataCombobox((prev: IFilterData) => ({
      ...prev,
      filterSelection: { ...prev.filterSelection, [key]: values },
    }))
  }

  const getAircraftAges = (aircraftsDOM: IFilterableData[]) => {
    const aircraftAges: IFilterableData[] = []
    aircraftsDOM.forEach((el) => {
      const newAircraftAge: IFilterableData = { label: getAssetAge(el.value), value: el.value }
      aircraftAges.push(newAircraftAge)
    })
    return aircraftAges
  }

  if (isNil(initialState.filterData.availableFilters) || isNil(availableFiltersData)) {
    return <></>
  }

  return (
    <Dialog
      contentClassName="w-150"
      triggerButton={triggerButton}
      headerActions={<Button variant={ButtonVariant.Ghost} label="Reset All" onClick={resetFilterData} className="ml-auto font-semibold" />}
      footerActions={[
        {
          variant: ButtonVariant.Secondary,
          size: Size.Small,
          label: 'Cancel',
          onClick: resetFilterData,
          isClose: true,
        },
        {
          variant: ButtonVariant.Primary,
          size: Size.Small,
          label: 'Apply',
          onClick: applyFilter,
          isClose: true,
        },
      ]}
    >
      <div className="my-4 grid grid-cols-2 gap-x-6 gap-y-10">
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.msns}
          itemLabel="MSN/ESN"
          label="MSN/ESN"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedMsn}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedMsn')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.lessees}
          itemLabel="Lessee"
          label="Lessee"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedLessee}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedLessee')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.aircraftModels}
          itemLabel="Aircraft Model"
          label="Aircraft Model"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedAircraftModel}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftModel')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.engineTypes}
          itemLabel="Engine Type"
          label="Engine Type"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedEngineType}
          setSelectedValues={(value: string[]) => {
            setFilterDataCombobox((prev: IFilterData) => ({ ...prev, selectedEngineType: value }))
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={getAircraftAges(availableFiltersData?.aircraftAges)}
          itemLabel="Aircraft Age"
          label="Aircraft Age"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedAircraftAge}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedAircraftAge')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.redeliveryDates}
          itemLabel="Redelivery Date"
          label="Redelivery Date"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedRedeliveryDate}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRedeliveryDate')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.regions}
          itemLabel="Region"
          label="Region"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedRegions}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedRegions')
          }}
        />
        <Combobox
          className="!w-67 gap-y-2"
          isMultiselect
          items={availableFiltersData?.portfolio}
          itemLabel="Portfolio"
          label="Portfolio"
          labelClassName="!text-base font-semibold text-text-1"
          selectedValues={filterDataCombobox.filterSelection.selectedPortfolio}
          setSelectedValues={(values: string[]) => {
            onSelectedFilterChanged(values, 'selectedPortfolio')
          }}
        />
      </div>
    </Dialog>
  )
}
