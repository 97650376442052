import { LessorContributionRates, EndOfLeaseRates } from '@flyward/assets/components/AircraftComponents/common'
import { type ComponentMaintenanceReserveRate, type LandingGear, type LandingGearMinimumReDeliveryCondition } from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  CheckTypesDisplay,
  DateCellWithLabel,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'

interface ILGContractualTabProps {
  landingGear: LandingGear
}

export const LGContractualTab = ({ landingGear }: ILGContractualTabProps) => {
  const contractDeliverySnapshot = landingGear.landingGearContract.componentContractDeliverySnapshot
  const maintenanceReserve = landingGear.landingGearContract.landingGearMaintenanceReserveContract
  const landingGearContract = landingGear.landingGearContract
  const rates = [maintenanceReserve.landingGearMaintenanceReserveRate]

  const minimumRedeliveryConditions = landingGear.landingGearContract.minimumReDeliveryConditions

  const Rates = rates.map((rate: ComponentMaintenanceReserveRate) => {
    if (rate === undefined) return null
    return (
      <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
        <BooleanCellWithLabel
          className="basis-1/6"
          label={`MR active`}
          info={landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} MR rate`}
          info={rate.rateAmount}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} rate type`}
          info={UtilizationUnitsDisplay(rate.utilizationUnit)}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} rate year`}
          info={rate.ratesYear}
          displayType={NumberDisplayType.Year}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`Current ${CheckTypesDisplay(rate.checkType)} MR fund`}
          info={rate.currentFund}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.CurrencyRounded}
        />
      </div>
    )
  })

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: LandingGearMinimumReDeliveryCondition) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )} - ${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full flex-col gap-x-6 gap-y-2">{Rates}</div>
      <div className="flex w-full gap-x-6 gap-y-2">
        <LessorContributionRates lessorContributions={landingGearContract.lessorContributions} />
        <BooleanCellWithLabel
          className="basis-1/6"
          label="MR Fund flushed at event"
          info={maintenanceReserve.isMaintenanceReserveFundFlushAtEvent}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="Overhaul MR Escalation"
          info={landingGearContract.escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={!landingGear.landingGearContract.landingGearMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.Percentage}
        />
        <div className="basis-1/6" />
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN @ Contract Delivery"
          info={contractDeliverySnapshot?.totalHoursSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN @ Contract Delivery"
          info={contractDeliverySnapshot?.cyclesSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <DateCellWithLabel
          className="basis-1/6"
          label="Last Overhaul date @ contract delivery"
          info={contractDeliverySnapshot?.dateAtLastEventAtContractDelivery}
        />
        <TextCellWithLabel className="mr-12 basis-3/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
      </div>
      <div className="flex gap-x-6">
        <BooleanCellWithLabel className="basis-1/6" label="EOL Applicable" info={landingGearContract.landingGearEndOfLeaseContract.isEOLApplicable} />
        <EndOfLeaseRates
          rates={[landingGearContract.landingGearEndOfLeaseContract?.landingGearEndOfLeaseRate]}
          isEolApplicable={landingGearContract?.landingGearEndOfLeaseContract?.isEOLApplicable}
        />
        <div className="mr-12 basis-3/6" />
      </div>
    </div>
  )
}
