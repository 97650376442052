import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse, type IGetAllLandingGearsByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const landingGearsApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.LandingGearsController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllLandingGearsByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          landingGearUtilizationSnapshot: {
            componentOpenUtilization: {
              hoursSinceEvent: 30946,
              cyclesSinceEvent: 21156,
              componentUtilizationSnapshotId: 'd8f64eee-3aaf-4c0b-850d-1f28cb3cdf7c',
            },
            landingGearId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 30946,
            cyclesSinceNew: 21156,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
                eventType: 4,
                totalHoursSinceNewAtLastQualifyingEvent: 26632,
                cyclesSinceNewAtLastQualifyingEvent: 18371,
                dateAtLastQualifyingEvent: '2018-10-17',
                id: 'a28f1493-2dfb-49c4-9579-becb83168398',
              },
            ],
          },
          landingGearContract: {
            landingGearId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            assetContractId: 'b7964078-9102-4e8f-a61b-ebffd7e25e9d',
            escalations: {
              createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              maintenanceReserveAgreedEscalationPercentage: 3,
              endOfLeaseAgreedEscalationPercentage: 3,
              id: '986f1391-5c55-4647-a92e-60409a90ff09',
            },
            landingGearMaintenanceReserveContract: {
              isMaintenanceReserveFundFlushAtEvent: false,
              createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
              landingGearMaintenanceReserveRate: {
                createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                landingGearMaintenanceReserveId: 'a2b8326f-dbc3-48fa-8dc4-6e6db0c027e9',
                componentMaintenanceReserveId: 'a2b8326f-dbc3-48fa-8dc4-6e6db0c027e9',
                checkType: 4,
                utilizationUnit: 1,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 3500,
                currentFund: 80000,
                id: '81bc69bd-158c-4753-ba00-cff2366333db',
              },
              isMaintenanceReserveActive: true,
              id: 'a2b8326f-dbc3-48fa-8dc4-6e6db0c027e9',
            },
            landingGearEndOfLeaseContract: {
              createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
              landingGearEndOfLeaseRate: {
                createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                typeOfEOLAdjustment: null,
                checkType: 4,
                rateAmount: 3500,
                id: 'c0abe3e1-ae05-44b6-a72b-9547e1a29fae',
              },
              isEOLApplicable: true,
              id: 'cc522694-e100-40a5-857a-5f64b4e8e984',
            },
            minimumReDeliveryConditions: [
              {
                createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 3,
                value: 4500,
                id: 'd8f64eee-3aaf-4c0b-850d-1f28cb3cdf7c',
              },
              {
                createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
                componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 1,
                value: 24,
                id: '8274e4eb-2939-4a55-9d22-e8aae245c02c',
              },
            ],
            componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            lessorContributions: {
              componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
              hasLessorContributions: false,
            },
            componentContractDeliverySnapshot: {
              cyclesSinceLastEventAtContractDelivery: 21156,
              totalHoursSinceLastEventAtContractDelivery: 500,
              componentContractId: '42f38a3a-73db-4859-8214-5d437b639545',
              totalHoursSinceNewAtContractDelivery: 30946,
              cyclesSinceNewAtContractDelivery: 21156,
              dateAtLastEventAtContractDelivery: '2018-10-04',
            },
            id: '42f38a3a-73db-4859-8214-5d437b639545',
          },
          componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
          componentSerialNumber: '10B12916J',
          componentCategory: 4,
          componentModel: 'B737-800',

          partNumber: 'NA28008-13',
          manufacturingDetail: {
            componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            manufacturer: 'Messier',
            dom: '2010-10-01',
          },
          assetComponentUtilizationSnapshot: {
            componentOpenUtilization: {
              hoursSinceEvent: 555,
              cyclesSinceEvent: 0,
              componentUtilizationSnapshotId: 'sadf8a3a-73db-4859-8214-5d437b639545',
            },
            componentId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 30946,
            cyclesSinceNew: 21156,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cb727512-eb6e-42f1-9a05-164ce076184e',
                eventType: 4,
                totalHoursSinceNewAtLastQualifyingEvent: 26632,
                cyclesSinceNewAtLastQualifyingEvent: 18371,
                dateAtLastQualifyingEvent: '2018-10-17',
                id: 'a28f1493-2dfb-49c4-9579-becb83168398',
              },
            ],
          },
          createdAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
          lastModifiedAt: new Date('2024-07-10T06:50:01.9620503+00:00'),
          aircraftId: 'sdaf8a3a-73db-4859-8214-5d437b639545',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.LandingGearsController.GetExpandedLandingGears('*'), () => {
    return HttpResponse.json<IGetAllLandingGearChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.LandingGear,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
  http.put(APIRoutes.AssetsModule.Components.LandingGearsController.MaintenanceProgram('*'), () => {
    return HttpResponse.json({
      status: 204,
    })
  }),
]

export { landingGearsApiMocks }
