import { LessorContributionRates, EndOfLeaseRates } from '@flyward/assets/components/AircraftComponents/common'
import {
  type ComponentMaintenanceReserveRate,
  type AuxiliaryPowerUnitMinimumReDeliveryCondition,
  type AuxiliaryPowerUnit,
} from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  CheckTypes,
  CheckTypesDisplay,
  DateCellWithLabel,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'

interface IAPUContractualTabProps {
  auxiliaryPowerUnit: AuxiliaryPowerUnit
}

export const APUContractualTab = ({ auxiliaryPowerUnit }: IAPUContractualTabProps) => {
  const contractDeliverySnapshot = auxiliaryPowerUnit.auxiliaryPowerUnitContract.componentContractDeliverySnapshot
  const maintenanceReserve = auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract
  const mockLLPRate: ComponentMaintenanceReserveRate = {
    componentMaintenanceReserveId: '1',
    checkType: CheckTypes.AuxiliaryPowerUnitLifeLimitedPartReplacement,
    maintenanceReserveCollectionType: null,
    utilizationUnit: null,
    ratesYear: null,
    rateAmount: null,
    currentFund: null,
    createdAt: new Date(),
    lastModifiedAt: new Date(),
    id: '',
  }

  const rates = [maintenanceReserve.auxiliaryPowerUnitPRMaintenanceReserveRate, mockLLPRate]
  const minimumRedeliveryConditions = auxiliaryPowerUnit.auxiliaryPowerUnitContract.minimumReDeliveryConditions
  const escalations = auxiliaryPowerUnit.auxiliaryPowerUnitContract.escalations
  const Rates = rates.map((rate: ComponentMaintenanceReserveRate) => {
    if (rate === undefined) return null
    return (
      <div className="flex w-full gap-x-6 gap-y-2" key={rate.checkType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} MR rate`}
          info={rate.rateAmount}
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
          currencyDecimals={2}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} rate type`}
          info={UtilizationUnitsDisplay(rate.utilizationUnit)}
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label={`${CheckTypesDisplay(rate.checkType)} MR collection type`}
          info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`Current ${CheckTypesDisplay(rate.checkType)} MR fund`}
          info={rate.currentFund}
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.CurrencyRounded}
        />
        <div className="basis-1/6"></div>
        <div className="basis-1/6"></div>
        {/** TODO change values when CheckType MR collection type is added */}
      </div>
    )
  })

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: AuxiliaryPowerUnitMinimumReDeliveryCondition) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )}\n${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-x-6 gap-y-2">
        <BooleanCellWithLabel
          className="basis-1/6"
          label="MR active"
          info={auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
        />
      </div>
      {Rates}
      <div className="flex w-full gap-x-6 gap-y-2">
        <LessorContributionRates lessorContributions={auxiliaryPowerUnit.auxiliaryPowerUnitContract.lessorContributions} />
        <BooleanCellWithLabel
          className="basis-1/6"
          label="MR Fund flushed at event"
          info={maintenanceReserve.isMaintenanceReserveFundFlushAtEvent}
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="PR MR Escalation"
          info={escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
          displayType={NumberDisplayType.Percentage}
        />
        <TextCellWithLabel
          className="basis-1/6"
          label="LLP MR Escalation"
          info="-"
          preventInfo={!auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitMaintenanceReserveContract.isMaintenanceReserveActive}
        />
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN @ Contract Delivery"
          info={contractDeliverySnapshot?.totalHoursSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN @ Contract Delivery"
          info={contractDeliverySnapshot?.cyclesSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <DateCellWithLabel
          className="basis-1/6"
          label="Last PR date @ contract delivery"
          info={contractDeliverySnapshot?.dateAtLastEventAtContractDelivery}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSLPR @ contract delivery"
          info={contractDeliverySnapshot?.totalHoursSinceLastEventAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSLPR @ contract delivery"
          info={contractDeliverySnapshot?.cyclesSinceLastEventAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel className="basis-1/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
      </div>

      <div className="flex gap-x-6">
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={auxiliaryPowerUnit.auxiliaryPowerUnitContract?.auxiliaryPowerUnitEndOfLeaseContract?.isEOLApplicable}
        />
        <EndOfLeaseRates
          rates={[auxiliaryPowerUnit.auxiliaryPowerUnitContract.auxiliaryPowerUnitEndOfLeaseContract?.auxiliaryPowerUnitPREndOfLeaseRate]}
          isEolApplicable={auxiliaryPowerUnit.auxiliaryPowerUnitContract?.auxiliaryPowerUnitEndOfLeaseContract?.isEOLApplicable}
        />
        <div className="mr-12 basis-3/6" />
      </div>
    </div>
  )
}
