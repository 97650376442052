import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse, type IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const auxiliaryPowerUnitsApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllAuxiliaryPowerUnitsByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          auxiliaryPowerUnitPRUtilizationSnapshot: {
            componentOpenUtilization: {
              componentUtilizationSnapshotId: 'd8f64eee-3aaf-4c0b-850d-1f28cb3cdf7c',
              cyclesSinceEvent: 21156,
              hoursSinceEvent: 40000,
            },
            auxiliaryPowerUnitId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 17499,
            cyclesSinceNew: 24009,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
                eventType: 5,
                totalHoursSinceNewAtLastQualifyingEvent: 20000,
                cyclesSinceNewAtLastQualifyingEvent: 23973,
                dateAtLastQualifyingEvent: '2020-09-14',
                id: '000ebd97-8f5a-41cb-8f39-3112997671bc',
              },
            ],
          },
          auxiliaryPowerUnitContract: {
            auxiliaryPowerUnitId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            escalations: {
              createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              maintenanceReserveAgreedEscalationPercentage: 3,
              endOfLeaseAgreedEscalationPercentage: 3,
              id: '57fdcf58-e59f-41ca-997a-5fc27364c393',
            },
            assetContractId: 'b7964078-9102-4e8f-a61b-ebffd7e25e9d',
            auxiliaryPowerUnitMaintenanceReserveContract: {
              createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              isMaintenanceReserveFundFlushAtEvent: false,
              componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
              auxiliaryPowerUnitPRMaintenanceReserveRate: {
                createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                auxiliaryPowerUnitMaintenanceReserveId: 'feff53d5-0f59-4c25-a07c-b60b5144347e',
                componentMaintenanceReserveId: 'feff53d5-0f59-4c25-a07c-b60b5144347e',
                checkType: 5,
                utilizationUnit: 4,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 32,
                currentFund: 175000,
                id: 'd5ecdf8c-4b9d-4b57-bcb1-20374d5a939b',
              },
              isMaintenanceReserveActive: true,
              id: 'feff53d5-0f59-4c25-a07c-b60b5144347e',
            },
            auxiliaryPowerUnitEndOfLeaseContract: {
              createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
              auxiliaryPowerUnitPREndOfLeaseRate: {
                createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                typeOfEOLAdjustment: 1,
                checkType: 5,
                rateAmount: 32,
                id: '16c250f5-7d43-4b55-a068-ef7f80afb84e',
              },
              isEOLApplicable: true,
              id: 'b8e1ea24-1418-4a69-bfaf-cf3300b93ece',
            },
            minimumReDeliveryConditions: [
              {
                createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
                componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
                minimumReDeliveryConditionType: 2,
                minimumReDeliveryConditionUnit: 2,
                value: 3000,
                id: 'beac8f27-cde3-4a4e-8c1d-495a2242a62a',
              },
            ],
            componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            lessorContributions: {
              componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
              hasLessorContributions: false,
            },
            componentContractDeliverySnapshot: {
              dateAtLastEventAtContractDelivery: '2024-07-10T06:50:01.9620494+00:00',
              cyclesSinceLastEventAtContractDelivery: 500,
              totalHoursSinceLastEventAtContractDelivery: 232,
              componentContractId: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
              totalHoursSinceNewAtContractDelivery: 24009,
              cyclesSinceNewAtContractDelivery: 17499,
            },
            id: '4e413e7b-acb0-439d-884f-f3259cf0da2f',
          },
          componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
          componentSerialNumber: '3072',
          componentCategory: 3,
          componentModel: 'APS 3200',
          manufacturingDetail: {
            componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            manufacturer: 'Hamilton Sundstrand International S.A.',
            dom: '2018-10-01',
          },
          assetComponentUtilizationSnapshot: {
            dateOfLastSnapshot: new Date('2023-03-01'),
            componentOpenUtilization: {
              componentUtilizationSnapshotId: 'd8f64eee-3aaf-4c0b-850d-1f28cb3cdf7c',
              cyclesSinceEvent: 21156,
              hoursSinceEvent: 40000,
            },
            componentId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
            totalHoursSinceNew: 17499,
            cyclesSinceNew: 24009,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'cba502eb-533a-47f1-a8a6-5066994f4d5f',
                eventType: 5,
                totalHoursSinceNewAtLastQualifyingEvent: 20000,
                cyclesSinceNewAtLastQualifyingEvent: 23973,
                dateAtLastQualifyingEvent: '2020-09-14',
                id: '000ebd97-8f5a-41cb-8f39-3112997671bc',
              },
            ],
          },
          aircraftId: 'sdaf8a3a-73db-4859-8214-5d437b639545',
          partNumber: 'NA28008-13',
          createdAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
          lastModifiedAt: new Date('2024-07-10T06:50:01.9620494+00:00'),
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.GetExpandedAuxiliaryPowerUnits('*'), () => {
    return HttpResponse.json<IGetAllAuxiliaryPowerUnitChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.AuxiliaryPowerUnit,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
  http.put(APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController.MaintenanceProgram('*'), () => {
    return HttpResponse.json({
      status: 204,
    })
  }),
]

export { auxiliaryPowerUnitsApiMocks }
