import { type EngineLifeLimitedPart } from '@flyward/assets/models'
import { NumberDisplay, SimpleCell, SimpleHeader } from '@flyward/platform'
import { type ColumnDef } from '@tanstack/react-table'

const llpTechnicalColumns: Array<ColumnDef<EngineLifeLimitedPart>> = [
  {
    id: 'module',
    header: () => <SimpleHeader title="Module" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="p-0 text-center text-xs text-text-1">{row.original.componentModule ?? '-'}</SimpleCell>,
  },
  {
    id: 'description',
    header: () => <SimpleHeader title="Description" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="p-0 text-center text-xs text-text-1">{row.original.componentModel ?? '-'}</SimpleCell>,
  },
  {
    id: 'partNumber',
    header: () => <SimpleHeader title="Part Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="p-0 text-center text-xs text-text-1">{row.original.partNumber ?? '-'}</SimpleCell>,
  },
  {
    id: 'componentSerialNumber',
    header: () => <SimpleHeader title="Serial Number" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <SimpleCell className="p-0 text-center text-xs text-text-1">{row.original.componentSerialNumber ?? '-'}</SimpleCell>,
  },
  {
    id: 'lifeLimit',
    header: () => <SimpleHeader title="LLP Life Limit" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => <NumberDisplay className="flex w-full justify-center p-0 text-xs text-text-1" value={row.original.technicalLimitAmount} />,
  },
  {
    id: 'cyclesSinceNew',
    header: () => <SimpleHeader title="Cycles Since New" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <NumberDisplay
        className="flex w-full justify-center p-0 text-xs text-text-1"
        value={row.original.assetComponentUtilizationSnapshot.cyclesSinceNew}
      />
    ),
  },
  {
    id: 'cyclesRemaining',
    header: () => <SimpleHeader title="Cycles Remaining" className="text-semibold m-0 w-full p-1 text-center text-xs text-black-0" />,
    cell: ({ row }) => (
      <NumberDisplay
        className="flex w-full justify-center p-0 text-xs text-text-1"
        value={row.original.assetComponentUtilizationSnapshot.remainingCycles}
      />
    ),
  },
]

export { llpTechnicalColumns }
