import { Input, InputType } from '@flyward/platform'
import { isNil } from 'lodash'
import { type Inflation } from '../../models'

interface IInflationDisplayProps {
  inflation?: Inflation
}

export const InflationDisplay = ({ inflation = undefined }: IInflationDisplayProps) => {
  return (
    <div className="flex gap-6">
      <Input
        key={!isNil(inflation) ? inflation.id : 'inflation-key'}
        id={!isNil(inflation) ? inflation.id : 'inflation-id'}
        controlledValue={inflation?.percentage.toString()}
        label={`Percentage`}
        type={InputType.Percentage}
      />
    </div>
  )
}
