import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse, type IGetAllAirframesByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const airframesApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.AirframesController.GetAll('*'), () => {
    return HttpResponse.json<IGetAllAirframesByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          airframeUtilizationSnapshot: {
            componentOpenUtilization: {
              componentUtilizationSnapshotId: 's4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
              cyclesSinceEvent: 0,
              hoursSinceEvent: 200,
            },
            airframeId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 35438,
            cyclesSinceNew: 24363,
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
                eventType: 72,
                totalHoursSinceNewAtLastQualifyingEvent: 20651,
                cyclesSinceNewAtLastQualifyingEvent: 14292,
                dateAtLastQualifyingEvent: '2017-01-19',
                id: '664111ea-c9c4-41a6-b0c2-c00924bc473d',
              },
              {
                componentUtilizationSnapshotId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
                eventType: 144,
                totalHoursSinceNewAtLastQualifyingEvent: 0,
                cyclesSinceNewAtLastQualifyingEvent: 0,
                dateAtLastQualifyingEvent: '2011-03-22',
                id: '43e75d74-1d6f-425b-8672-e8dce45989c8',
              },
            ],
          },
          airframeContract: {
            airframeId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            assetContractId: 'b7964078-9102-4e8f-a61b-ebffd7e25e9d',
            escalations: {
              createdAt: new Date('2024-07-10'),
              lastModifiedAt: new Date('2024-07-10'),
              maintenanceReserveAgreedEscalationPercentage: 3,
              endOfLeaseAgreedEscalationPercentage: 3,
              id: '6d4ad956-1af8-47ea-a9e2-0f895f33adaa',
            },
            airframeMaintenanceReserveContract: {
              isMaintenanceReserveFundFlushAtEvent: false,
              createdAt: new Date('2024-07-10'),
              lastModifiedAt: new Date('2024-07-12'),
              airframeMaintenanceRates: [
                {
                  airframeMaintenanceReserveId: 'd9045f29-e8a4-4a97-a893-3d3c6107be82',
                  createdAt: new Date('2024-07-10'),
                  lastModifiedAt: new Date('2024-07-12'),
                  componentMaintenanceReserveId: 'd9045f29-e8a4-4a97-a893-3d3c6107be82',
                  checkType: 144,
                  utilizationUnit: 2,
                  maintenanceReserveCollectionType: 1,
                  ratesYear: 2021,
                  rateAmount: 6000,
                  currentFund: 200000,
                  id: '3b7d2ac4-785c-4d3d-8ec5-0620cb6ba810',
                },
              ],
              isMaintenanceReserveActive: true,
              id: 'd9045f29-e8a4-4a97-a893-3d3c6107be82',
            },
            airframeEndOfLeaseContract: {
              createdAt: new Date('2024-07-10'),
              lastModifiedAt: new Date('2024-07-12'),
              airframeEndOfLeaseRates: [],
              isEOLApplicable: false,
              id: '85da5925-5dc6-4443-88ad-5e04c1f54c24',
            },
            minimumReDeliveryConditions: [
              {
                createdAt: new Date('2024-07-10'),
                lastModifiedAt: new Date('2024-07-12'),
                componentContractId: '16fcdcad-3d27-4d5e-8f3a-51a03c412cfd',
                minimumReDeliveryConditionType: 1,
                minimumReDeliveryConditionUnit: 1,
                value: 12,
                id: '6ce2ba87-b4c6-4523-9e64-09399aa0f5c3',
              },
            ],
            agreedStubLife: 'N/A',
            componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            lessorContributions: {
              componentContractId: '16fcdcad-3d27-4d5e-8f3a-51a03c412cfd',
              hasLessorContributions: false,
            },
            componentContractDeliverySnapshot: {
              componentContractId: '16fcdcad-3d27-4d5e-8f3a-51a03c412cfd',
              totalHoursSinceNewAtContractDelivery: 27403,
              cyclesSinceNewAtContractDelivery: 18768,
              dateAtLastEventAtContractDelivery: '2018-10-04',
              cyclesSinceLastEventAtContractDelivery: 23,
              totalHoursSinceLastEventAtContractDelivery: 44,
            },
            id: '16fcdcad-3d27-4d5e-8f3a-51a03c412cfd',
          },
          componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          componentSerialNumber: '30027',
          componentCategory: 1,
          componentModel: 'A320-200',
          manufacturingDetail: {
            componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            manufacturer: 'Airbus',
            dom: '2022-03-11',
          },
          assetComponentUtilizationSnapshot: {
            componentId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
            dateOfLastSnapshot: new Date('2023-03-01'),
            totalHoursSinceNew: 35438,
            cyclesSinceNew: 24363,
            componentOpenUtilization: {
              componentUtilizationSnapshotId: 's4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
              cyclesSinceEvent: 44,
              hoursSinceEvent: 55,
            },
            componentUtilizationAtEvents: [
              {
                componentUtilizationSnapshotId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
                eventType: 72,
                totalHoursSinceNewAtLastQualifyingEvent: 20651,
                cyclesSinceNewAtLastQualifyingEvent: 14292,
                dateAtLastQualifyingEvent: '2017-01-19',
                id: '664111ea-c9c4-41a6-b0c2-c00924bc473d',
              },
              {
                componentUtilizationSnapshotId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
                eventType: 144,
                totalHoursSinceNewAtLastQualifyingEvent: 0,
                cyclesSinceNewAtLastQualifyingEvent: 0,
                dateAtLastQualifyingEvent: '2011-03-22',
                id: '43e75d74-1d6f-425b-8672-e8dce45989c8',
              },
            ],
          },
          aircraftId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          assetContractId: 'zszzzzzz-3d27-4d5e-8f3a-51a03c412cfd',
          createdAt: new Date('2024-07-10'),
          lastModifiedAt: new Date('2024-07-12'),
          partNumber: '5000',
          createdByUserId: 's4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.AirframesController.GetExpandedAirframes('*'), () => {
    return HttpResponse.json<IGetAllAirframeChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.Airframe,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
  http.put(APIRoutes.AssetsModule.Components.AirframesController.MaintenanceProgram('*'), () => {
    return HttpResponse.json({
      status: 204,
    })
  }),
]

export { airframesApiMocks }
