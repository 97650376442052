import { CheckTypesDisplay, formatDate, NumberCellWithLabel, NumberDisplayType, TextCellWithLabel } from '@flyward/platform'
import { Fragment } from 'react'
import { cloneDeep } from 'lodash'
import { type Airframe } from '@flyward/assets/models'

interface IAirframeTechnicalTabProps {
  airframe: Airframe
}

export const AirframeTechnicalTab = ({ airframe }: IAirframeTechnicalTabProps) => {
  const utilizationAtEvents = airframe.airframeUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = cloneDeep(utilizationAtEvents)
    .sort((a, b) => Number(a.eventType) - Number(b.eventType))
    .map((event) => {
      return (
        <Fragment key={event.eventType}>
          <TextCellWithLabel
            className="basis-1/6"
            label={`Date @ last ${CheckTypesDisplay(event.eventType)}`}
            info={formatDate(event.dateAtLastQualifyingEvent?.toString())}
          />
        </Fragment>
      )
    })
  return (
    <div className="flex w-full gap-x-6 gap-y-2">
      <NumberCellWithLabel
        className="basis-1/6"
        label="TSN"
        info={airframe.airframeUtilizationSnapshot.totalHoursSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      <NumberCellWithLabel
        className="basis-1/6"
        label="CSN"
        info={airframe.airframeUtilizationSnapshot.cyclesSinceNew}
        displayType={NumberDisplayType.Integer}
      />
      {UtilizationAtEventComponent}
      <div className="mr-6 basis-2/6" />
    </div>
  )
}
