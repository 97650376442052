import { type AuxiliaryPowerUnit } from '@flyward/assets/models'
import { CheckTypesDisplay, DateCellWithLabel, NumberCellWithLabel, NumberDisplayType } from '@flyward/platform'
import { Fragment } from 'react'

interface IAPUTechnicalTabProps {
  auxiliaryPowerUnit: AuxiliaryPowerUnit
}

export const APUTechnicalTab = ({ auxiliaryPowerUnit }: IAPUTechnicalTabProps) => {
  const utilizationAtEvents = auxiliaryPowerUnit.auxiliaryPowerUnitPRUtilizationSnapshot.componentUtilizationAtEvents

  const UtilizationAtEventComponent = utilizationAtEvents.map((event) => {
    return (
      <Fragment key={event.eventType}>
        <NumberCellWithLabel
          className="basis-1/6"
          label={`TSN @ last ${CheckTypesDisplay(event.eventType)}`}
          info={event.totalHoursSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label={`CSN @ last ${CheckTypesDisplay(event.eventType)}`}
          info={event.cyclesSinceNewAtLastQualifyingEvent}
          displayType={NumberDisplayType.Integer}
        />
        <DateCellWithLabel className="basis-1/6" label={`Date @ last ${CheckTypesDisplay(event.eventType)}`} info={event.dateAtLastQualifyingEvent} />
      </Fragment>
    )
  })
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-x-6 gap-y-2">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN"
          info={auxiliaryPowerUnit.auxiliaryPowerUnitPRUtilizationSnapshot.totalHoursSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN"
          info={auxiliaryPowerUnit.auxiliaryPowerUnitPRUtilizationSnapshot.cyclesSinceNew}
          displayType={NumberDisplayType.Integer}
        />
        {UtilizationAtEventComponent}
        <div className="basis-1/6" />
      </div>
    </div>
  )
}
